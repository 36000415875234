import i18n from "../../../../../configs/i18n";

const Properties = (field, t = i18n.t) => {
  const config = field.get("config");
  const trsPrefix =
    "catalogEditor.fieldProperties.configProperties.field." +
    field.get("type") +
    ".";

  // type
  let type = config.get("type");
  type = type == "text" && config.get("mask") ? "masktext" : type;

  return {
    properties: [
      {
        id: "configPropertiesSection",
        name: t("catalogEditor.fieldProperties.configProperties.section"),
        type: "group"
      },
      {
        id: "config.type",
        name: t(trsPrefix + "type.label"),
        type: "radiobutton",
        readOnly: false,
        config: {
          mode: "dropdown",
          items: [
            {
              id: "text",
              name: t(trsPrefix + "type.items.text")
            },
            {
              id: "masktext",
              name: t(trsPrefix + "type.items.masktext")
            },
            {
              id: "multiline",
              name: t(trsPrefix + "type.items.multiline")
            },
            {
              id: "textEditor",
              name: t(trsPrefix + "type.items.textEditor")
            }
          ]
        }
      },
      {
        id: "config.mask",
        name: t(trsPrefix + "mask.label"),
        type: "text",
        readOnly: false,
        hint: t(trsPrefix + "mask.hint"),
        config: {
          type: "text"
        },
        visible: {
          "config.type": {
            $in: ["text", "masktext"]
          }
        }
      }
    ],

    values: {
      "config.type": type,
      "config.mask": config.get("mask")
    },

    getValues: (propery, value) => {
      switch (propery) {
        case "config.type":
          if (value == "masktext") {
            return {
              "config.type": "text"
            };
          } else {
            return {
              "config.type": value,
              "config.mask": ""
            };
          }
          break;
      }
      return { [propery]: value };
    }
  };
};

export default Properties;

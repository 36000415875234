import _ from "lodash";
import FIELD_TYPES from "../configs/fieldTypes";

export default function getFilterValues(filters, catalogs, catalogId, user) {
  const FieldApi = require("../models/FieldApi").default;
  let fields = catalogs.getIn([catalogId, "fields"]);
  let fieldPrivilegeCodes = catalogs.getIn([catalogId, "fieldPrivilegeCodes"]);
  let valuesFromFilters = {};
  _.forEach(filters, (fieldFilter, fieldId) => {
    const field = fields.find(f => f.get("id") == fieldId);
    const hidden = field && field.get("hidden");
    // если филда нет или он скрыт ничего не делаеи
    if (!field || hidden) {
      return;
    }
    const canChooseFromExistingRecords =
      FIELD_TYPES.OBJECT === field.get("type")
        ? field.getIn(["config", "enableSelect"])
        : true;
    // проверка на не редактируемость поля
    const editable = fieldPrivilegeCodes.get(fieldId);
    const isNotEditable =
      field.get("apiOnly") === true ||
      (editable && editable !== "edit") ||
      !canChooseFromExistingRecords;

    // если поле не редактируемое, то ничего не делаем с фильтрами
    if (isNotEditable) return;

    // конвертация значений фильтров в значение записи
    let values = FieldApi.convertFilterToRecordValue(
      field,
      fieldFilter,
      catalogs,
      user
    );

    // проверка на мултиселектность
    const multiselect = field.getIn(["config", "multiselect"]);
    if (multiselect === false) values = values?.slice(0, 1);
    // применение значечий из записи
    valuesFromFilters[fieldId] = values;
  });
  return valuesFromFilters;
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".appBody__container___vRXWj {\n  position: absolute;\n  bottom: 47px;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/appBody.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;AAAF","sourcesContent":["@import (reference) \"../../../styles/helpers/index.less\";\n.container {\n  position: absolute;\n  bottom: @HEADER_BASE;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "appBody__container___vRXWj"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewBody__container___ACZUm {\n  position: absolute;\n  bottom: 0;\n  top: 81px;\n  left: 0;\n  right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionBody/Catalog/View/ViewBody/viewBody.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,SAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AAAF","sourcesContent":["@import (reference) \"../../../../../../../../styles/helpers/index.less\";\n.container {\n  position: absolute;\n  bottom: 0;\n  top: @HEADER_BASE+@RECORD_DATA_HEADER - 1;\n  left: 0;\n  right: 0;\n  // overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "viewBody__container___ACZUm"
};
export default ___CSS_LOADER_EXPORT___;

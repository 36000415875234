import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { DragSource } from "react-dnd";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import dndTargets from "../../../configs/dndTargets";
import FIELD_TYPES from "../../../configs/fieldTypes";
import dragAndDropActions from "../../../actions/dragAndDropActions";
import fieldTypeIcons from "../../../configs/fieldTypeIcons";
import styles from "./fieldsPanel.less";

const fieldNameByType = {};
_.forEach(
  FIELD_TYPES,
  (id, name) => (fieldNameByType[id] = name.toLowerCase())
);

const typesWithMargin = {
  [FIELD_TYPES.GROUP]: true,
  [FIELD_TYPES.ADDRESS]: true,
  [FIELD_TYPES.RADIOBUTTON]: true,
  [FIELD_TYPES.FILE]: true,
  [FIELD_TYPES.STARS]: true,
  [FIELD_TYPES.BUTTON]: true,
};

const dragSource = DragSource(
  dndTargets.FIELD_TYPE,
  {
    beginDrag(props, monitor, component) {
      let item = { fieldType: component.props.type };
      dragAndDropActions.beginDrag(dndTargets.FIELD_TYPE, item);
      return item;
    },
    endDrag() {
      dragAndDropActions.endDrag();
    }
  },
  function collect(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    };
  }
);

class FieldTypesItem extends React.PureComponent {
  render() {
    const { connectDragSource, isDragging } = this.props;

    let classes = classNames({
      [styles.field]: true,
      [styles.fieldWithMargin]: typesWithMargin[this.props.type],
      [styles.section]: this.props.type === FIELD_TYPES.GROUP,
      dragging: isDragging
    });
    return connectDragSource(
      <div className={classes}>
        <div className={styles.fieldIcon}>
          <div
            className={
              "anticon-icon " +
              (fieldTypeIcons[this.props.type] || "programing-21")
            }
          />
        </div>
        <div className={styles.fieldIconBorder} />
        <span className={styles.fieldText}>
          {this.props.t(`fieldTypes.${fieldNameByType[this.props.type]}.name`)}
        </span>
      </div>
    );
  }
}

FieldTypesItem.propTypes = {
  type: PropTypes.string.isRequired
};

export default withTranslation()(dragSource(FieldTypesItem));

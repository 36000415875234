import { InputMappers, OutputMappers } from "./helpers/mappers";
import i18n from "../../../../configs/i18n";

const Properties = (field, t = i18n.t) => {
  return {
    properties: [
      {
        id: "technicalPropertiesSection",
        name: t("catalogEditor.fieldProperties.technicalProperties.section"),
        type: "group"
      },
      {
        id: "id",
        name: t("catalogEditor.fieldProperties.technicalProperties.id.label"),
        type: "text",
        readOnly: true,
        config: {
          type: "text"
        }
      },
      {
        id: "filterable",
        name: t(
          "catalogEditor.fieldProperties.technicalProperties.filterable.label"
        ),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(
                "catalogEditor.fieldProperties.technicalProperties.filterable.value"
              )
            }
          ]
        }
      },
      {
        id: "history",
        name: t(
          "catalogEditor.fieldProperties.technicalProperties.history.label"
        ),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(
                "catalogEditor.fieldProperties.technicalProperties.history.value"
              )
            }
          ]
        }
      },
      {
        id: "apiOnly",
        name: t(
          "catalogEditor.fieldProperties.technicalProperties.apiOnly.label"
        ),
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(
                "catalogEditor.fieldProperties.technicalProperties.apiOnly.value"
              )
            }
          ]
        }
      },
      {
        id: "comment",
        name: t("catalogEditor.fieldProperties.technicalProperties.comment"),
        type: "text",
        readOnly: false,
        config: {
          type: "multiline"
        }
      }
    ],

    values: {
      id:
        field.get("id") ||
        t("catalogEditor.fieldProperties.technicalProperties.id.noValue"),
      history: InputMappers.boolToCheckbox(field.get("history")),
      filterable: InputMappers.boolToCheckbox(field.get("filterable")),
      apiOnly: InputMappers.boolToCheckbox(field.get("apiOnly")),
      comment: field.get("comment")
    },

    getValues: (propery, value) => {
      switch (propery) {
        case "history":
        case "filterable":
        case "apiOnly":
          value = OutputMappers.checkboxToBool(value);
          break;
      }
      return { [propery]: value };
    }
  };
};

export default Properties;

import React from "react";
import { Link } from "react-router-dom";
import { Menu as AntMenu, Dropdown } from "antd";
import cn from "classnames";
import CatalogItem from "./CatalogItem";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Icon from "../../../common/UI/Icon";
import ButtonTransparent from "../../../common/UI/ButtonTransparent";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import { checkAccessOnObject } from "../../../../utils/rights";
import apiActions from "../../../../actions/apiActions";
import modalsActions from "../../../../actions/modalsActions";
import { promptConfirm, prompt } from "../../../common/Modal";

import styles from "./companies.less";

class SectionItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAccessAdmin: false,
      sectionId: this.props.section.get("id"),
      isCatalogsCollaps: false
    };
  }
  static propTypes = {
    sectionCatalogs: PropTypes.object,
    onFavoriteClick: PropTypes.func,
    favoriteCatalogs: PropTypes.object,
    hidePopover: PropTypes.func,
    section: PropTypes.object
  };

  componentDidUpdate(prevProps) {
    if (prevProps.section !== this.props.section) {
      const isAccessAdmin = checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        this.props.section,
        PRIVILEGE_CODES.ADMIN
      );
      this.setState({ isAccessAdmin });
    }

    if (this.props.section.get("id") !== prevProps.section.get("id")) {
      this.setState({ sectionId: this.props.section.get("id") });
    }
  }

  componentDidMount() {
    const isAccessAdmin = checkAccessOnObject(
      RESOURCE_TYPES.SECTION,
      this.props.section,
      PRIVILEGE_CODES.ADMIN
    );
    this.setState({ isAccessAdmin });
  }

  onClickAccess = () => {
    this.props.hidePopover();

    if (this.state.sectionId) {
      const isAdmin = checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        this.props.section,
        PRIVILEGE_CODES.ADMIN
      );
      const readOnly = !checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        this.props.section,
        PRIVILEGE_CODES.ACCESS
      );
      const object = { sectionId: this.state.sectionId };
      modalsActions.openAccessModal(
        { object },
        RESOURCE_TYPES.SECTION,
        { readOnly, isAdmin },
        true
      );
    }
  };

  openRenameModal = () => {
    this.props.hidePopover();
    const { t } = this.props;

    prompt({
      headerText: t("modals.changeSectionName.headerText"),
      value: this.props.section.get("title"),
      onOk: this.onRename,
      okText: t("buttons.save"),
      cancelText: t("buttons.cancel")
    });
  };

  onRename = newName => {
    apiActions.updateSection(
      {
        sectionId: this.state.sectionId
      },
      {
        name: newName
      }
    );
  };

  openRemoveConfirm = () => {
    this.props.hidePopover();
    const { t } = this.props;
    const sectionName = this.props.section.get("name");

    promptConfirm({
      value: sectionName,
      placeHolder: sectionName,
      headerText: t("modals.removeSectionConfirm.headerText"),
      text: (
        <span>
          {t("modals.removeSectionConfirm.firstText")}
          <br />
          {t("modals.removeSectionConfirm.secondText")}
        </span>
      ),
      okText: t("modals.removeSectionConfirm.okText"),
      cancelText: t("modals.removeSectionConfirm.cancelText"),
      onOk: () =>
        apiActions
          .deleteSection({
            sectionId: this.state.sectionId
          })
          .then(() => {
            this.props.history.replace({
              pathname: "/",
              search: this.props.location.search
            });
          })
    });
  };

  render() {
    const {
      favoriteCatalogs,
      onFavoriteClick,
      hidePopover,
      sectionCatalogs,
      t
    } = this.props;

    const { isCatalogsCollaps } = this.state;

    let menu = [
      {
        key: 1,
        label: (
          <a onClick={this.onClickAccess}>
            <Icon type={"icon edition-55"} className={styles.settingIcon} />
            {t("buttons.accessSection")}
          </a>
        )
      }
    ];

    if (this.state.isAccessAdmin) {
      const adminItemsMenu = [
        {
          key: 2,
          label: (
            <a onClick={this.openRenameModal}>
              <Icon type={"icon edition-33"} className={styles.settingIcon} />
              {t("buttons.renameSection")}
            </a>
          )
        },
        {
          key: 3,
          label: (
            <a
              onClick={this.openRemoveConfirm}
              className={styles.settingRemove}
            >
              <Icon type={"icon edition-41"} className={styles.settingIcon} />
              {t("buttons.removeSection")}
            </a>
          )
        }
      ];

      menu = [...menu, ...adminItemsMenu];
    }

    return (
      <div className={styles.sectionItem}>
        <div className={styles.sectionTitle}>
          <h2 className={styles.sectionTitleText}>
            {this.props.section.get("title")}
          </h2>
          <Dropdown key="dropdown" menu={{ items: menu }} trigger={["click"]}>
            {/* Configure section button */}
            <ButtonTransparent
              className={styles.sectionButton}
              title={t("buttons.configureSection")}
            >
              <Icon
                type="icon setting-10"
                className={styles.sectionTitleIcon}
              />
            </ButtonTransparent>
          </Dropdown>
          {/* Collapse catalogs button */}
          <ButtonTransparent
            className={styles.sectionButton}
            title={t("buttons.collapseCatalogs")}
          >
            <Icon
              type={
                isCatalogsCollaps
                  ? "icon arrows-chevron-big-1-01"
                  : "icon arrows-chevron-big-2-01"
              }
              className={styles.sectionTitleIcon}
              onClick={() =>
                this.setState(prevState => ({
                  isCatalogsCollaps: !prevState.isCatalogsCollaps
                }))
              }
            />
          </ButtonTransparent>
        </div>
        <ul className={styles.catalogList}>
          {sectionCatalogs &&
            !isCatalogsCollaps &&
            sectionCatalogs.valueSeq().map((sectionCatalog, index) => {
              const catalogId = sectionCatalog.get("id");

              const isFavorite =
                favoriteCatalogs && favoriteCatalogs.indexOf(catalogId) > -1;
              return (
                <CatalogItem
                  key={index}
                  sectionCatalog={sectionCatalog}
                  isFavorite={isFavorite}
                  onFavoriteClick={onFavoriteClick}
                  hidePopover={hidePopover}
                />
              );
            })}
          {this.state.isAccessAdmin && !isCatalogsCollaps ? (
            <li
              className={cn(styles.catalogItem, styles.catalogItemAdd)}
              onClick={this.props.hidePopover}
            >
              <Link
                to={`/section/${this.state.sectionId}/add`}
                key="submit catalog"
                type="primary"
                className={styles.popoverFooterButton}
              >
                <Icon className={styles.catalogIcon} type="icon interface-72" />
                {t("sidebar.newCatalog")}
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    );
  }
}

export default withTranslation()(SectionItem);

import _ from "lodash";
import React, { Component, lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import { matchPath } from "react-router";
import { Row } from "antd";

import apiActions from "../../../../actions/apiActions";
import uiActions from "../../../../actions/uiActions";
import { connect } from "../../../StateProvider";

import PRIVILEGE_CODES from "../../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../../utils/rights";


import routes from "../../../../routes";
import NavRoute from "../../../common/router/Route";
import DefaultRedirect from "../../../common/router/DefaultRedirect";

import CatalogEditor from "../../../catalogEditor";

import styles from "./section.less";
import Loading from "../../../common/Loading";

const CatalogsMenu = lazy(() => import("./CatalogsMenu"));
const SectionSettings = lazy(() => import("./SectionSettings"));
const SectionBody = lazy(() => import("./SectionBody"));

class Section extends Component {
  componentDidMount() {
    const { sectionId } = this.props.match.params;

    if (sectionId) {
      apiActions.getSection({ sectionId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSectionId = nextProps.match.params.sectionId;

    if (newSectionId && newSectionId !== this.props.match.params.sectionId) {
      // update catalogs.
      apiActions.getSection({ sectionId: newSectionId });
    }
  }

  componentDidUpdate(prevProps) {
    this.showHelper();
    if (!_.isEqual(prevProps.location, this.props.location)) {
      uiActions.unsetHelperCode("sectionCatalogCreation");
    }
  }

  showHelper() {
    const { catalogs, match } = this.props;
    const { sectionId } = match.params;
    const currentCatalogs =
      catalogs &&
      catalogs.filter(catalog => catalog.get("sectionId") == sectionId);
    // Check if section loaded and no catalogs present
    if (
      catalogs &&
      catalogs.size &&
      currentCatalogs &&
      currentCatalogs.size === 0
    ) {
      uiActions.showHelper("sectionCatalogCreation");
    }
  }

  render() {
    const { hoveredSectionId} = this.props;

    let sectionId = this.props.match.params.sectionId;
    sectionId = hoveredSectionId ? hoveredSectionId : sectionId;


    const section = this.props.sections.get(sectionId);
    const catalogs = this.props.catalogs
      .valueSeq()
      .filter(
        c =>
          c.get("sectionId") === sectionId &&
          // show catalogs with privilegeCode > available (search, view, ...)
          c.get("privilegeCode") !== "available"
      )
      .sortBy(c => c.get("index"))

    const firstCatalog = catalogs.get(0);
    
    // todo: check access for
    const isAccessAdmin = checkAccessOnObject(
      RESOURCE_TYPES.SECTION,
      section,
      PRIVILEGE_CODES.ADMIN
    );

    return (
      <section className={styles.container}>
        <Row
          type="flex"
          justify="start"
          align="middle"
          className={styles.sectionHeaderWrapper}
        > 
          <Suspense fallback={<Loading />}>
            <CatalogsMenu
              sectionId={sectionId}
              catalogs={catalogs}
              isAccessAdmin={isAccessAdmin}
              history={this.props.history}
              location={this.props.location}
            />
            <SectionSettings
              section={section}
              isAccessAdmin={isAccessAdmin}
              history={this.props.history}
            />
          </Suspense>
          
        </Row>

        <Route
          render={props => {
            const { location } = props;
            const matchEdit = matchPath(location.pathname, {
              path: routes.catalogEdit.path,
              exact: false,
              strict: false
            });
            const matchAdd = matchPath(location.pathname, {
              path: routes.catalogAdd.path,
              exact: false,
              strict: false
            });

            if (matchEdit) {
              return (
                  <NavRoute
                    route={routes.catalogEdit}
                    render={props => {
                      return (<CatalogEditor isStateEditCatalog={true} {...props} />);
                    }}
                  />
              );
            } else if (matchAdd) {
                return (<CatalogEditor isStateAddCatalog={true} />)
            } else {
              return (
                <Suspense fallback={<Loading />}>
                  <DefaultRedirect
                    route={routes.catalog}
                    params="catalogId"
                    object={firstCatalog}
                  />
                  <SectionBody />
                </Suspense>
              );
            }
          }}
        />
      </section>
    );
  }
}

export default connect(
  Section,
  {
    sections: ["sections"],
    catalogs: ["catalogs"],
    sidebarMenu: ["ui", "sidebarMenu"],
    hoveredSectionId: ["ui", "hoveredSectionId"]
  }
);

import React, { Component, lazy, Suspense } from "react";
import { withRouter } from "react-router";
import { Row } from "antd";
import { withTranslation } from "react-i18next";

import FieldsPanel from "./FieldsPanel";
import PropertyPanel from "./PropertyPanel";
// import CatalogEditorBody from "./CatalogEditorBody";
// import CatalogEditorHeader from "./CatalogEditorHeader";
import apiActions from "../../actions/apiActions";
import catalogActions from "../../actions/catalogActions";
import { connect } from "../StateProvider";
import uiActions from "../../actions/uiActions";
import userSettingsActions from "../../actions/userSettingsActions";

import styles from "./catalogEditor.less";
import _ from "lodash";

import Loading from "../common/Loading";

const CatalogEditorHeader = lazy(() => import("./CatalogEditorHeader"))
const CatalogEditorBody = lazy(() => import("./CatalogEditorBody"))

class CatalogEditor extends Component {
  state = {
    selectedFieldIndex: null
  };

  loadCatalog() {
    const catalogId = this.props.match.params.catalogId;
    const sectionId = this.props.match.params.sectionId;
    
    userSettingsActions.getKey(
      ["catalogs", catalogId, "catalogEditor", "compactFields"].join(".")
    );

    if (this.props.isStateEditCatalog) {
      apiActions.getCatalog(
        {
          catalogId: catalogId
        },
        null,
        { catalogEdit: "catalogEdit", sceneId: this.props.sceneId }
      );
    } else {
      catalogActions.addCatalog({
        sectionId: sectionId,
        t: this.props.t
      });
    }
  }

  componentDidMount() {
    this.loadCatalog();
  }

  componentDidUpdate(prevProps) {
    uiActions.showHelper("systemCreation");
    const catalogId = this.props.match.params.catalogId;
    const prevCatalogId = prevProps.match.params.catalogId;

    if ( catalogId != prevCatalogId ) {
      this.loadCatalog();
    }
  }

  onSelectField = fieldIndex =>
    this.setState({ selectedFieldIndex: fieldIndex });

  render() {
    const sectionId = this.props.match.params.sectionId;
    const catalog = !_.isEmpty(this.props.editingCatalogs)
      ? this.props.editingCatalogs.get(sectionId)
      : null;
    const { catalogs, dropType, dropInfo } = this.props;
    const disabled =
      catalog && (catalog.get("updating") || catalog.get("creating"));
    return (
      <div className={styles.catalogEditorWrapper}>
        <div className={styles.catalogEditor}>
          {catalog ? (
            <Suspense fallback={<Loading />}>
              <CatalogEditorHeader
                {...this.props}
                catalog={catalog}
                disabled={disabled}
              />

            </Suspense>
          ) : null}
          {catalog ? (
            <div className={styles.catalogEditorBody}>
              <Row type="flex" className={styles.catalogEditorData}>
                <Suspense fallback={<Loading />}>
                  <FieldsPanel />
                  <CatalogEditorBody
                    {...this.props}
                    dropType={dropType}
                    dropInfo={dropInfo}
                    catalog={catalog}
                    catalogs={catalogs}
                    disabled={disabled}
                    selectedFieldIndex={this.state.selectedFieldIndex}
                    onSelectField={this.onSelectField}
                  />
                  <PropertyPanel
                    {...this.props}
                    selectedFieldIndex={this.state.selectedFieldIndex}
                  />
                </Suspense>


              </Row>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(
  withRouter(withTranslation()(CatalogEditor)),
  ["catalogs", "editingCatalogs", "dropType", "dropInfo"]
);

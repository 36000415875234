import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import ControlList from "../../common/UI/ControlList";
import FieldProperties from "./FieldProperties";

import styles from "./propertyPanel.less";
class Properties extends PureComponent {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    field: PropTypes.object
  };

  state = {
    fieldProperties: {}
  };

  prepareData(field, catalog) {
    if (!field) {
      return;
    }

    const catalogFields = catalog && catalog.get("fields");
    const fieldProperties = FieldProperties(field, catalogFields, this.props.t);

    this.setState({ fieldProperties });
  }

  onChange = (property, value) => {
    //this.onEndEditing(property, value);
  };

  onEndEditing = (property, value) => {
    let field = this.props.field;
    const fieldProperties = this.state.fieldProperties;

    // convert property to field format value
    const values = fieldProperties.getValues(property, value);

    // merge property to field
    _.forEach(values, (value, key) => {
      const path = key.split(".");
      if (value === undefined) {
        field = field.deleteIn(path);
      } else {
        field = field.setIn(path, value);
      }
    });

    // update
    this.props.onSaveField && this.props.onSaveField(field);
  };

  componentDidMount() {
    this.prepareData(this.props.field, this.props.catalog);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newField = nextProps.field && nextProps.field.toJS();
    const field = this.props.field && this.props.field.toJS();

    const newCatalog = nextProps.catalog && nextProps.catalog.toJS();
    const catalog = this.props.catalog && this.props.catalog.toJS();

    if (!_.isEqual(newField, field) || !_.isEqual(newCatalog, catalog)) {
      this.prepareData(nextProps.field, nextProps.catalog);
    }
  }

  render() {
    const properties = this.state.fieldProperties.properties;
    const values = this.state.fieldProperties.values;

    if (!properties) {
      return null;
    }

    return (
      <ControlList
        labelOnTop={true}
        compact={true}
        data={{
          configs: properties,
          values: values
        }}
        className={styles.propertiesControlList}
        onEndEditing={this.onEndEditing}
        onChange={this.onChange}
        keyForStorage={"catalogEditor"}
      />
    );
  }
}

export default withTranslation()(Properties);

import { InputMappers, OutputMappers } from "./helpers/mappers";
import i18n from "../../../../configs/i18n";

const Properties = (field, t = i18n.t) => {
  return {
    properties: [
      {
        id: "generalPropertiesSection",
        name: t("catalogEditor.fieldProperties.generalProperties.section"),
        type: "group"
      },
      {
        id: "name",
        name: t("catalogEditor.fieldProperties.generalProperties.name"),
        type: "text",
        readOnly: false,
        config: {
          type: "text"
        }
      },
      {
        id: "hint",
        name: t("catalogEditor.fieldProperties.generalProperties.hint"),
        type: "text",
        readOnly: false,
        config: {
          type: "multiline"
        }
      },
      {
        id: "required",
        name: "",
        type: "checkboxes",
        readOnly: false,
        config: {
          items: [
            {
              id: "true",
              name: t(
                "catalogEditor.fieldProperties.generalProperties.required"
              )
            }
          ]
        }
      }
    ],

    values: {
      name: field.get("name"),
      hint: field.get("hint"),
      required: InputMappers.boolToCheckbox(field.get("required"))
    },

    getValues: (propery, value) => {
      switch (propery) {
        case "required":
          value = OutputMappers.checkboxToBool(value);
          break;
      }
      return { [propery]: value };
    }
  };
};

export default Properties;

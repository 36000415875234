import React from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import Icon from "../../common/UI/Icon";
import dndContext from "../../../services/dndContext";
import FieldTypesItem from "./FieldTypesItem";
import { WHITE_LIST } from "../../../configs/fieldTypes";

import styles from "./fieldsPanel.less";

class FieldsPanel extends React.PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.fieldInfo}>
          {this.props.t("catalogEditor.dragFieldToEditor")}
          <Icon type="icon interface-6" />
        </div>

        <div>
          {_.map(WHITE_LIST, fieldType => {
            return <FieldTypesItem key={fieldType} type={fieldType} />;
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(dndContext(FieldsPanel));

import Reflux from "reflux";

const actions = Reflux.createActions({
  sidebarMenuFixing: { sync: false },
  leftPanelVisible: { sync: false },

  toggleHelper: { sync: true },
  setHelperCode: { sync: true },
  showHelper: { sync: true },
  unsetHelperCode: { sync: true },
  setShowChatTabInModal: { sync: true },
  sectionMenuHover: { sync: true }
});

export default actions;
